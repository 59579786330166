<script>
/* COMPOSANTS */
import AkFormView from "@components/layout/AkFormView";
import AkCalendar from "@components/input/AkCalendar";
import AkInputText from "@components/input/AkInputText";
import AkInputNumber from "@components/input/AkInputNumber";

/* MIXINS */
import randomRef from "@mixins/randomRef";
import multiLineMixin from "@mixins/multiLineMixin";
import roleMixin from "@mixins/roleMixin";

/* SERVICES */
import bilanFormationService from "@services/bilanFormationService";

export default {
  components: {AkFormView, AkCalendar, AkInputText, AkInputNumber},
  mixins: [randomRef, roleMixin, multiLineMixin],
  metaInfo() {
    return {
      title: "bilan.details",
    }
  },
  data() {
    return {
      current: {}
    }
  },
  mounted() {
    this.getRef().showTotalLoader();

    let msg = this.$route.query.msg;
    if (msg && msg !== "") {
      this.getRef().success(msg);
    }

    let p1 = bilanFormationService.findById(this.$route.params.id);
    p1.then(data => this.current = data);

    Promise.all([p1]).then(()=>{
      this.current.materielCommentaire = this.getTextParsed(this.current.materielCommentaire);
      this.current.circuitCommentaire = this.getTextParsed(this.current.circuitCommentaire);
      this.current.rivhCommentaire = this.getTextParsed(this.current.rivhCommentaire);
      this.getRef().hideLoader();
    });
  }
}
</script>

<template v-if=!roleLoading>
  <AkFormView :ref="ref" :title="$t('bilan.details')">
    <template v-slot:action>
      <RouterLink v-if="current.id !== undefined && this.canEditFormation() && !current.year"
                  :to="{ name: 'bilanFormationUpdate', params: {id: current.id}}"
                  class="btn btn-inverse-primary btn-xs"><i
        class="fe fe-edit pr-1"/>{{ $t('update') }}
      </RouterLink>
    </template>
    <template v-slot:form>
      <div class="form-row">
        <AkInputText :disabled=true v-model=current.agerLabel class-name="col-md-4" :label="$t('ager_label')"/>
        <AkInputText :disabled=true v-model=current.uerLabel class-name="col-md-4" :label="$t('uer_label')"/>
        <AkInputText :disabled=true v-model=current.ceiLabel class-name="col-md-4" :label="$t('cei_label')"/>
      </div>

        <h5>{{ $t('bilan_formation.title1') }}</h5>

        <div class="form-row">
          <AkInputText
              :disabled=true
              v-model="current.gpsDiff"
              :label="$t('bilan_formation.gps_diff')"
              class-name="col-md-6"/>
          <AkInputText
              :disabled=true
              v-model="current.gpsForm"
              :label="$t('bilan_formation.gps_form')"
              class-name="col-md-6"/>
        </div>

        <h5>{{ $t('bilan_formation.title2') }}</h5>

        <div class="form-row">
          <AkCalendar
              :disabled=true
              v-model="current.pevhDateParution"
              :label="$t('bilan_formation.pevh_date_parution')"
              class-name="col-md-4"/>
          <AkCalendar
              :disabled=true
              v-model="current.pevhDateValidation"
              :label="$t('bilan_formation.pevh_date_validation')"
              class-name="col-md-4"/>
          <AkInputText
              :disabled=true
              v-model="current.pevhModeDiffusion"
              :label="$t('bilan_formation.pevh_mode_diffusion')"
              class-name="col-md-4"/>
        </div>
        
        <h5>{{ $t('bilan_formation.title3') }}</h5>

        <div class="form-row">
          <AkInputText
              :disabled=true
              :rows=getRowCount(current.materielForme)
              v-model="current.materielForme"
              :label="$t('bilan_formation.materiel_forme')"
              class-name="col-md-6"/>
          <div class="form-group col-md-6">
            <label>{{ $t('bilan_formation.materiel_commentaire') }}</label>
            <div class="form-control" style="background: #e9ecef; color: #495057; opacity: 1; display: table;" v-html="current.materielCommentaire"></div>
          </div>
        </div>
        
        <h5>{{ $t('bilan_formation.title4') }}</h5>

        <div class="form-row">
          <AkInputText
              :disabled=true
              v-model="current.circuitForme"
              :label="$t('bilan_formation.circuit_forme')"
              class-name="col-md-6"/>
          <div class="form-group col-md-6">
            <label>{{ $t('bilan_formation.circuit_commentaire') }}</label>
            <div class="form-control" style="background: #e9ecef; color: #495057; opacity: 1; display: table;" v-html="current.circuitCommentaire"></div>
          </div>
        </div>
        
        <h5>{{ $t('bilan_formation.title5') }}</h5>

        <div class="form-row">
          <AkInputNumber
              :disabled=true
              v-model="current.rivhNb"
              :label="$t('bilan_formation.rivh_nb')"
              class-name="col-md-6"/>
          <div class="form-group col-md-6">
            <label>{{ $t('bilan_formation.rivh_commentaire') }}</label>
            <div class="form-control" style="background: #e9ecef; color: #495057; opacity: 1; display: table;" v-html="current.rivhCommentaire"></div>
          </div>
        </div>
    </template>
  </AkFormView>
</template>